import { render, staticRenderFns } from "./createWithdrawal.vue?vue&type=template&id=6971f6c4&scoped=true&"
import script from "./createWithdrawal.vue?vue&type=script&lang=js&"
export * from "./createWithdrawal.vue?vue&type=script&lang=js&"
import style0 from "./createWithdrawal.vue?vue&type=style&index=0&id=6971f6c4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6971f6c4",
  null
  
)

export default component.exports