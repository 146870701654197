<template>
  <div>
    <b-modal @hidden="resetModal" ref="modal-withdrawal" centered hide-footer size="lg" :no-close-on-backdrop="true" content-class="overflow-hidden">
      <template #modal-title>
        <h5 class="text-info mb-0 text-dark">
          <i class="uil uil-money-withdrawal text-primary"></i> Request Withdrawal
        </h5>
      </template>
      <div class="row align-items-center">
        <div class="col-12">
          <p class="font-size-14 text-muted mb-3">To request a withdrawal from the funds holder wallet.</p>
        </div>
        <div class="col-12">
          <div class="mb-3 row">
            <label class="col-lg-3 col-12 col-form-label">Reseller</label>
            <div class="col-lg-9 col-12">
              <multiselect v-model="selectedReseller" :options="reseller" label="account_name" placeholder="Select Reseller" :close-on-select="true" :allow-empty="false" deselect-label="" :show-on-select="true" :disabled="loading" @input="getResellerContract()" :class="{'is-invalid':modalSubmit && $v.formData.resellerId.$error }">
                  <template slot="singleLabel" slot-scope="props">
                    <span v-if="props.option.account_db_code!=='all'">
                      <span class="option__title">
                        <span v-if="props.option.reseller_level!=='' && account_type=='admin'">
                          <span class="badge bg-info font-size-12" v-if="props.option.reseller_level=='0'">Z</span>
                          <span class="badge bg-info font-size-12" v-else>Level {{ props.option.reseller_level }}</span>
                        </span>
                       #{{ props.option.id }} {{ props.option.account_name }} 
                      </span>
                    </span>
                    <span v-else>
                      {{ props.option.account_name }}
                            <span v-if="reseller.length>1">({{ reseller.length-1 }})</span>
                        
                    </span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <span v-if="props.option.account_db_code!=='all'">
                      <span class="option__title">
                        <span v-if="props.option.reseller_level!=='' && account_type=='admin'">
                          <span class="badge bg-info font-size-12" v-if="props.option.reseller_level=='0'">Z</span>
                          <span class="badge bg-info font-size-12" v-else>Level {{ props.option.reseller_level }}</span>
                        </span>
                        #{{ props.option.id }} {{ props.option.account_name }} 
                      </span>
                    </span>
                    <span v-else>
                      {{ props.option.account_name }} <span v-if="reseller.length>1">({{ reseller.length-1 }})</span>
                    </span>
                  </template>
                  <span slot="noResult">Oops! No reseller found. </span>
                </multiselect>
              <div v-if="modalSubmit && $v.formData.resellerId.$error" class="invalid-feedback">
                <span v-if="!$v.formData.resellerId.required">Reseller is required.</span>
                <span v-else-if="!$v.formData.resellerId.equalDefault">Reseller Id is required.</span>
              </div>
            </div>
          </div>
          <div class="mb-3 row">
            <label class="col-lg-3 col-12 col-form-label">Available Balance</label>
            <div class="col-lg-9 col-12">
              <multiselect
                  ref="select"
                  v-model="selectedResellerContract"
                  :options="resellerContract"
                  label="account_name"
                  class="mb-3"
                  placeholder="Select"
                  :close-on-select="true"
                  :allow-empty="false"
                  deselect-label=""
                  :show-on-select="true"
                  :disabled="disabled"
                  @open="isOpen = true"
                  @close="isOpen = false"
                  @input="changeContract()"
                >
                <template slot="singleLabel" slot-scope="props">
                  <span v-if="props.option.contract_id!==''">
                    <span v-if="props.option.contract_status=='unavailable'" class="text-warning me-1">&#9679;</span>
                    <span v-else-if="props.option.contract_status=='available'" class="text-success me-1">&#9679;</span>
                    <span class="option__title"> {{ props.option.reseller_code }}: {{ props.option.contract_name }} - {{ props.option.contract_currency }} 
                      <span v-if="props.option.contract_balance">
                        {{ convertCurrencyFormat(props.option.contract_balance, true)}}
                      </span>
                      <span v-else>0.00</span>
                    </span>
                  </span>
                  <span v-else>{{ props.option.contract_name }} </span>
                </template>
                <template slot="option" slot-scope="props">
                  <span v-if="props.option.contract_id!==''">
                    <span v-if="props.option.contract_status=='unavailable'" class="text-warning me-1">&#9679;</span>
                    <span v-else-if="props.option.contract_status=='available'" class="text-success me-1">&#9679;</span>
                    <span class="option__title"> {{ props.option.reseller_code }}: {{ props.option.contract_name }} - {{ props.option.contract_currency }} 
                      <span v-if="props.option.contract_balance">
                        {{ convertCurrencyFormat(props.option.contract_balance, true)}}
                      </span>
                      <span v-else>0.00</span>
                    </span>
                  </span>
                  <span v-else>{{ props.option.contract_name }}</span>
                </template>
                <span slot="noResult">Oops! No Contract found. </span>
                </multiselect>
              <div v-if="modalSubmit && $v.formData.contractId.$error" class="invalid-feedback">
                <span v-if="!$v.formData.contractId.required">Contract is required.</span>
                <span v-else-if="!$v.formData.contractId.equalDefault">Contract Id is required.</span>
              </div>
              <div class="mt-2" v-if="selectedResellerContract.contract_id !==''">
                <h5 class="font-size-14 bg-soft-primary text-primary p-2 fw-medium mb-0 d-inline-block rounded">
                  <i class="uil uil-wallet"></i>
                  {{selectedResellerContract.contract_currency}}
                  <span v-if="selectedResellerContract.contract_balance">
                    {{ convertCurrencyFormat(selectedResellerContract.contract_balance, true)}}
                  </span>
                  <span v-else>0.00</span>
                </h5>
              </div>
            </div>
          </div>
         
          <div class="mb-3 row">
            <label class="col-lg-3 col-12 col-form-label">Amount to Withdraw <span class="text-muted d-block fw-normal font-size-13">(Min. {{selectedResellerContract.contract_currency}} <span v-if="currencyMin">{{ convertCurrencyFormat(currencyMin,false) }}</span> )</span></label>
            <div class="col-lg-9 col-12">
              <div class="input-group">
                <span class="input-group-text" id="basic-addon1">{{selectedResellerContract.contract_currency}}</span>
                <input :disabled="!selectedResellerContract.contract_balance>0 || loading" v-model.trim="formData.withdrawAmount" type="tel" @input="inputNumberOnly($event, 'withdrawAmount'), $v.formData.$touch();" @blur="changeCurrency()" class="form-control" placeholder="Amount to Withdraw" name="withdrawAmount" :class="{
                    'is-invalid':
                    modalSubmit && $v.formData.withdrawAmount.$error
                  }" />
                <div v-if="modalSubmit && $v.formData.withdrawAmount.$error" class="invalid-feedback">
                  <span v-if="!$v.formData.withdrawAmount.required">Withdraw Amount is required.</span>
                  <span v-if="!$v.formData.withdrawAmount.lessThanBalance">Please make sure that the withdraw amount, including charges, remains within the limit of  {{ selectedResellerContract.contract_currency }}  {{ convertCurrencyFormat(currencyMax, true)}}</span>
                  <span v-if="!$v.formData.withdrawAmount.minSettlement"> Must be greater than the minimum withdraw amount.</span>
                </div>
                <!-- <div v-if="modalSubmit && $v.formData.withdrawAmountAfterCovertRate.$error" class="invalid-feedback">
                  <span v-if="!$v.formData.withdrawAmountAfterCovertRate.minSettlement">Must be greater than the minimum settlement amount.</span>
                </div> -->
              </div>
            </div>
          </div>
          <div class="mb-3 row">
            <label class="col-lg-3 col-12 col-form-label">Reseller Receives Amount</label>
            <div class="col-lg-9 col-12">
              <div class="bg-light p-3 px-3 rounded">
                <table class="table mb-0">
                <tr>
                  <td class="border-bottom border-secondary pb-2 align-top">Withdrawal Amount</td>
                  <td class="text-end border-bottom border-secondary pb-2 align-top"> 
                    <span v-if="formData.withdrawAmount" class="p-0"> {{ selectedResellerContract.contract_currency }} {{ convertCurrencyFormat(formData.withdrawAmount || 0, true)}}
                    </span>
                    <span v-else class="p-0"> {{ selectedResellerContract.contract_currency }} 0.00
                    </span>
                  </td>
                </tr>
                <tr>
                  <td class="pt-2">Reseller Receives Amount
                  </td>
                  <td class="text-end pt-2"> 
                    <span class="p-0 text-success fw-medium" v-if="selectedResellerContract.contract_balance">{{ selectedResellerContract.contract_currency }} {{calculateTotal()}} </span>
                    <span v-else class="p-0">-</span>
                  </td>
                </tr>
                </table>
               </div>
            </div>
          </div>
          <div class="mb-3 row">
            <label class="col-lg-3 col-12 col-form-label">Contract Balance After Withdrawal </label>
            <div class="col-lg-9 col-12">
              <div class="bg-light p-3 px-3 rounded">
                <table class="table mb-0">
                <tr>
                  <td>Currenct Balance</td>
                  <td class="text-end"> 
                    <span class="p-0" v-if="selectedResellerContract.contract_balance">{{ selectedResellerContract.contract_currency }} {{ convertCurrencyFormat(selectedResellerContract.contract_balance, true)}}
                    </span>
                    <span class="p-0" v-else>{{ selectedResellerContract.contract_currency }} 0.00</span>
                  </td>
                </tr>
                <tr>
                  <td>Withdraw Amount</td>
                  <td class="text-end"> 
                    <span v-if="formData.withdrawAmount" class="p-0">- {{ selectedResellerContract.contract_currency }} {{ convertCurrencyFormat(formData.withdrawAmount || 0, true)}}
                    </span>
                    <span v-else class="p-0"> - {{ selectedResellerContract.contract_currency }} 0.00
                    </span>
                  </td>
                </tr>
                <tr>
                  <td class="border-bottom border-secondary pb-2 align-top">Payout Rate and Extra Charges
                    <span v-if="selectedResellerContract.payout_rate" class="d-block p-0 font-size-13 text-muted">({{selectedResellerContract.payout_rate }}% + {{ selectedResellerContract.contract_currency }} {{ convertCurrencyFormat(selectedResellerContract.payout_extra_charges,true) }})</span></td>
                    <td class="text-end border-bottom border-secondary pb-2 align-top"> 
                      <span class="p-0" v-if="formData.withdrawAmount">- {{ selectedResellerContract.contract_currency }} {{ convertCurrencyFormat(calculateCharge(), true) }}
                      </span> 
                      <span v-else class="p-0">-</span>
                  </td>
                </tr>
                <tr>
                  <td class="pt-2">Contract Balance
                  </td>
                  <td class="text-end pt-2"> 
                    <span class="p-0 fw-medium" v-if="selectedResellerContract.contract_balance" :class="(parseFloat(calculateBalance())>=0) ? 'text-success' : 'text-danger'">{{ selectedResellerContract.contract_currency }} {{calculateBalance()}} </span>
                    <span v-else class="p-0">-</span>
                  </td>
                </tr>
                </table>
               </div>
            </div>
          </div>
          <div class="mb-3 row">
            <label class="col-lg-3 col-12 col-form-label">Select Currency</label>
            <div class="col-lg-9 col-12">
              <select class="form-select" aria-label="" v-model="formData.amountToReceiveCurrency" @change="changeCurrency" :class="{
                        'is-invalid':
                        modalSubmit && $v.formData.amountToReceiveCurrency.$error,
                      }" :disabled="!formData.withdrawAmount">
                <option value="">Select Currency</option>
                <option v-for="(value, index) in currencyList" :value="value" :key="index">
                  {{value.displayName}} 
                  <!-- (Min {{value.shortName}}  {{convertCurrencyFormat(value.minSettlementAmount, true)}}) -->
                </option>
              </select>
              <div v-if="modalSubmit && $v.formData.amountToReceiveCurrency.$error" class="invalid-feedback">
                <span v-if="!$v.formData.amountToReceiveCurrency.required">Please select the currency for withdrawal.</span>
              </div>
              <!-- <div class="fw-medium mt-2 p-3 bg-light text-info rounded" v-if="formData.withdrawAmountAfterCovertRate!==''">
                <span class="spinner-border spinner-border-sm me-2 border-1" v-if="loading" role="status" aria-hidden="true"></span>
                {{formData.amountToReceiveCurrency.shortName}} {{ convertCurrencyFormat(formData.withdrawAmountAfterCovertRate,true) }}</div>
               -->
            </div>
          </div>
          <div>
            <div class="mb-3 row">
              <label class="col-lg-3 col-12 col-form-label" v-if="formData.amountToReceiveCurrency.shortName!=='USDT'">Receiving Bank</label>
              <label class="col-lg-3 col-12 col-form-label" v-else>Cryptocurrency</label>
              <div class="col-lg-9 col-12">
                <multiselect v-if="banksList.length" v-model="selectedBanks" @input="updateBank" :options="banksList" label="name" class="" group-label="category" group-values="list" placeholder="Select Bank" track-by="name" :close-on-select="true" :allow-empty="false" deselect-label="" select-label="" :show-on-select="true" :disabled="disabled || formData.amountToReceiveCurrency == ''" :class="{
                        'is-invalid':
                        modalSubmit && $v.formData.amountToReceiveCurrency.$error,
                      }">
                  <!-- <template slot="singleLabel" slot-scope="props"><span v-if="props.option.list!==-1">
                        {{ props }}
                        <span class="option__title">{{ props.option.name }}</span></span></template><template slot="option" slot-scope="props"><span v-if="props.option.id!==-1"><span class="option__title">{{ props.option.name }}</span></span></template> -->
                  <span slot="noResult">Oops! No bank found. </span>
                </multiselect>
                <div v-if="modalSubmit && $v.formData.bankName.$error" class="invalid-feedback">
                  <span v-if="!$v.formData.bankName.required">Receiving Bank is required.</span>
                  <span v-else-if="!$v.formData.bankName.equalDefault">Receiving Bank is required.</span>
                </div>
                <div>
                  <input v-if="!(banksList.length>0)" v-model.trim="formData.bankName" type="tel" class="form-control" placeholder="Receiving Bank" name="bankName" :disabled="banksList.length>0 || formData.amountToReceiveCurrency == '' || formData.amountToReceiveCurrency.shortName=='USDT'" :class="{
                        'is-invalid':
                        modalSubmit && $v.formData.bankName.$error,
                      }" />
                  <div v-if="modalSubmit && $v.formData.bankName.$error" class="invalid-feedback">
                    <span v-if="!$v.formData.bankName.required">Receiving Bank is required.</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-3 row">
              <label class="col-lg-3 col-12 col-form-label" v-if="formData.amountToReceiveCurrency.shortName!=='USDT'"> Beneficiary Account No.</label>
              <label class="col-lg-3 col-12 col-form-label" v-else>Wallet Address</label>
              <div class="col-lg-9 col-12" v-if="formData.amountToReceiveCurrency.shortName!=='USDT'">
                <input v-model.trim="formData.accountNumber" type="tel" class="form-control" placeholder="Account Number" name="accountNumber" :class="{
                    'is-invalid':
                    modalSubmit && $v.formData.accountNumber.$error,
                  }" :disabled="disabled || formData.amountToReceiveCurrency == ''" />
                <div v-if="modalSubmit && $v.formData.accountNumber.$error" class="invalid-feedback">
                  <span v-if="!$v.formData.accountNumber.required">Beneficiary Account No. is required.</span>
                </div>
              </div>
              <div class="col-lg-9 col-12" v-else>
                <input v-model.trim="formData.accountNumber" type="text" class="form-control" placeholder="Wallet Address" name="accountNumber" :class="{
                    'is-invalid':
                    modalSubmit && $v.formData.accountNumber.$error,
                  }" :disabled="disabled || formData.amountToReceiveCurrency == ''" />
                <div v-if="modalSubmit && $v.formData.accountNumber.$error" class="invalid-feedback">
                  <span v-if="!$v.formData.accountNumber.required">Receiver's  Address is required.</span>
                </div>
              </div>
            </div>
            <div class="mb-3 row">
              <label class="col-lg-3 col-12 col-form-label" v-if="formData.amountToReceiveCurrency.shortName!=='USDT'">Beneficiary Name</label>
              <label class="col-lg-3 col-12 col-form-label" v-else>Network</label>
              <div class="col-lg-9 col-12" v-if="formData.amountToReceiveCurrency.shortName!=='USDT'">
                <input v-model.trim="formData.accountName" type="text" class="form-control" placeholder="Account Name" name="accountName" :class="{
                    'is-invalid':
                    modalSubmit && $v.formData.accountName.$error,
                  }" :disabled="disabled || formData.amountToReceiveCurrency == ''" />
                <div v-if="modalSubmit && $v.formData.accountName.$error" class="invalid-feedback">
                  <span v-if="!$v.formData.accountName.required">Beneficiary Name is required.</span>
                </div>
              </div>
              <div class="col-lg-9 col-12" v-else>
                <input v-model.trim="formData.accountName" type="text" class="form-control" placeholder="Network" name="accountName" :class="{
                    'is-invalid':
                    modalSubmit && $v.formData.accountName.$error,
                  }" :disabled="disabled || formData.amountToReceiveCurrency == '' || formData.amountToReceiveCurrency.shortName=='USDT'" />
                <div v-if="modalSubmit && $v.formData.accountName.$error" class="invalid-feedback">
                  <span v-if="!$v.formData.accountName.required">Network is required.</span>
                </div>
              </div>
            </div>
            <div class="mb-3 row" v-if="formData.extraField1.enabled">
              <label
                class="col-lg-3 col-12 col-form-label"
                >{{formData.extraField1.name}}</label
              >
              <div
                class="col-lg-9 col-12"
              >
                <input
                  v-model.trim="formData.extraField1.value"
                  type="text"
                  class="form-control"
                  :placeholder="formData.extraField1.name"
                  name="accountName"
                  :disabled="disabled || formData.amountToReceiveCurrency == ''"
                />
              </div>
            </div>
            <div class="mb-3 row" v-if="formData.extraField2.enabled">
              <label
                class="col-lg-3 col-12 col-form-label"
                >{{formData.extraField2.name}}</label
              >
              <div
                class="col-lg-9 col-12"
              >
                <input
                  v-model.trim="formData.extraField2.value"
                  type="text"
                  class="form-control"
                  :placeholder="formData.extraField2.name"
                  name="accountName"
                  :disabled="disabled || formData.amountToReceiveCurrency == ''"
                />
              </div>
            </div>
            <div class="mb-3 row" v-if="formData.extraField3.enabled">
              <label
                class="col-lg-3 col-12 col-form-label"
                >{{formData.extraField3.name}}</label
              >
              <div
                class="col-lg-9 col-12"
              >
                <input
                  v-model.trim="formData.extraField3.value"
                  type="text"
                  class="form-control"
                  :placeholder="formData.extraField3.name"
                  name="accountName"
                  :disabled="disabled || formData.amountToReceiveCurrency == ''"
                />
              </div>
            </div>
            <div class="mb-3 row">
              <label class="col-lg-3 col-12 col-form-label">Preferred Transaction Description <span class="text-muted d-block fw-normal  font-size-13">(Optional)</span>
              </label>
              <div class="col-lg-9 col-12">
                <textarea rows="4" v-model.trim="formData.transactionDescription" type="text" class="form-control" placeholder="Preferred Transaction Description" name="transactionDescription" :disabled="disabled || formData.amountToReceiveCurrency == ''"></textarea>
                <!-- <div
                  v-if="modalSubmit && $v.formData.transactionDescription.$error"
                  class="invalid-feedback"
                ><span v-if="!$v.formData.transactionDescription.required"
                    >Payout Description is required.</span
                  ></div> -->
              </div>
            </div>
            <!-- <div class="mb-3 row">
              <label class="col-lg-3 col-12 col-form-label">Your Login Password</label>
              <div class="col-lg-9 col-12">
                <div class="position-relative">
                  <input v-model="formData.password" :type="passwordFieldType" name="password" class="form-control" :class="{
                    'is-invalid':
                    modalSubmit && $v.formData.password.$error,
                  }" placeholder="Password" :disabled="disabled || formData.amountToReceiveCurrency == ''" />
                  <div v-if="modalSubmit && $v.formData.password.$error" class="invalid-feedback">
                    <span v-if="!$v.formData.password.required">Password is required.</span>
                    <span v-if="!$v.formData.password.minLength">Password must be at least 8 characters.</span>
                  </div>
                  <div id="eye" class="user-select-none" @click="switchVisibility()">
                    <i :class="classicon" id="eyetype" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
            <p class="text-muted text-end font-size-12">
              <i class="uil uil-exclamation-triangle font-size-15 text-warning me-2"></i> Password is Case Sensitive.
            </p> -->
            <hr>
            <div class="d-grid gap-2 d-md-block text-end">
              <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-withdrawal'].hide()">Cancel</button>
              <button type="button" class="btn fw-medium btn-info" @click="addWithdrawalRequest" :disabled="modalLoading"  v-if="selectedResellerContract.contract_id !==''">
                <span v-if="modalLoading"> Submitting...</span>
                <span v-else> Submit </span>
                <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <Common ref="commonFunc" />
  </div>
</template>
<script>
import appConfig from "@/app.config";
import axios from "axios";
import Swal from "sweetalert2";
import Common from "@/components/common";
import Multiselect from "vue-multiselect";
import {
  required
} from "vuelidate/lib/validators";
//const alpha = helpers.regex("alpha", /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/);
// const validWebsiteUrl = (value) => {
//   console.log(value);
//   const regex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+)\.([a-zA-Z]{2,})(\/\S*)?$/;
//   return regex.test(value);
// }
// const validUsername = (value) => {
//   const regex = /^[a-z0-9]+$/;
//   return regex.test(value);
// };
const equalDefault = (value) => {
  return value !== -1;
}
const CancelToken = axios.CancelToken;
let cancel;
/**
 * Starter page
 */
const BankDefault = []
const resellerContractDefault ={
  "status": "0",
  "payin_rate": 0,
  "contract_id": "",
  "payout_rate": 0,
  "total_payin": 0,
  "ewallet_rate": 0,
  "total_payout": 0,
  "contract_name": "Select Contract",
  "contract_total": 0,
  "contract_status": "",
  "settlement_rate": 0,
  "contract_balance": 0,
  "total_settlement": 0,
  "contract_currency": "",
  "payin_extra_charges": 0,
  "payout_extra_charges": 0,
  "ewallet_extra_charges": 0,
  "settlement_extra_charges": 0,
  "contract_list_length_total": 0,
  "account_name": "",
  "reseller_id": "",
  "reseller_name": "",
  "reseller_code": "",
  "reseller_db_code": "",
  "reseller_level": "",
  "reseller_parent": []
}
const ResellerDefault =
{
  "account_db_code": "all",
  "account_name": "All Resellers",
  "account_type": "",
  "up_reseller_count": 0,
  "reseller_parent": [],
  "reseller_level": "",
  "reseller_code": "",
  "merchant_count": 0
}
  export default {
    components: {
      Multiselect,
      Common
    },
    props: {
      data: {
        type: Object,
        required: true,
      }
    },
    data() {
      return {
        currencyMin: 0,
        currencyMax: 0,
        accessToken: '',
        accessEmail: '',
        accessPhone: '',
        accessUsername: '',
        account_type:'',
        selectedReseller: ResellerDefault,
        reseller:[ResellerDefault],
        selectedResellerContract: resellerContractDefault,
        resellerContract: [resellerContractDefault],
        selectedBanks: [],
        banksList: [],
        currencyList: [],
        currencyChargePercentage: 0,
        loading: false,
        disabled: false,
        modalSubmit: false,
        modalLoading: false,
        passwordFieldType: "password",
        classicon: "uil uil-eye-slash",
        passwordFieldType2: "password",
        classicon2: "uil uil-eye-slash",
        returnData: [],
        formData: {
          resellerId: "",
          contractId: "",
          amountToReceiveCurrency: "",
          bankName: "",
          accountNumber: "",
          accountName: "",
          transactionDescription: "",
          withdrawAmount: "",
          extraField1: {
            name: "",
            value: "",
            enabled: false,
          },
          extraField2: {
            name: "",
            value: "",
            enabled: false,
          },
          extraField3: {
            name: "",
            value: "",
            enabled: false,
          }
          //withdrawAmountAfterCovertRate: "",
          //password: "",
        },
        minWithdrawal:[]
      };
    },
    validations: {
      formData: {
        resellerId: {
          required,
          equalDefault
        },
        contractId: {
          required,
          equalDefault
        },
        bankName: {
          required,
          equalDefault
        },
        amountToReceiveCurrency: {
          required,
        },
        accountNumber: {
          required,
        },
        accountName: {
          required,
        },
        withdrawAmount: {
          required,
          lessThanBalance(value) {
            return (value <= parseFloat(this.currencyMax));
          },
          minSettlement(value) {
            return (value >= parseFloat(this.currencyMin));
          },
        },
        // withdrawAmountAfterCovertRate: {
        //   required,
        //   minSettlement(value) {
        //     return (value >= parseFloat(this.currencyMin));
        //   },
        // },
        // password: {
        //   required,
        //   minLength: minLength(8),
        // }
      },
    },
    middleware: "authentication",
    async mounted() {
      //   this.title = PageTitle
      //   this.items[1].text = PageTitle
      //   // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
      //   // this.$refs.main.changeVerticalTopBar("bill",true)
      //   // this.$refs.main.setShowFooterCert(false)
      //   // this.$refs.main.setPageTitle('title')
      //   console.log( this.$refs.commonFunc.getPhone())
      //   this.accessToken = this.$refs.commonFunc.getToken()
      //   this.accessUsername = this.$refs.commonFunc.getUsername()
      //   await this.getData();
      this.reload() 
    },
    created() {},
    methods: {
      updateBank() {
        this.formData.bankName = this.selectedBanks.name
      },
      calculateCharge(){
        var amount = this.formData.withdrawAmount || 0
        var charges = parseFloat(0.00)
        if (this.formData.withdrawAmount){
          console.log(this.selectedResellerContract.payout_rate)
          charges = ( parseFloat(amount) * parseFloat((this.selectedResellerContract.payout_rate/100))) + parseFloat(this.selectedResellerContract.payout_extra_charges)
        }
        //this.currencyMax = this.selectedResellerContract.contract_balance + charges
        return charges
      },
      calculateTotal(){
        var amount = parseFloat(this.formData.withdrawAmount ||0)
        //var charges = this.calculateCharge()
        var total = 0.00
        total =  amount 
        //total =  amount + charges
        //console.log(amount, charges, total)
        total = this.convertCurrencyFormat(total,true)
        return total
      },
      calculateBalance(){
        var amount = this.formData.withdrawAmount
        var charges = parseFloat(this.calculateCharge())
        var balance = parseFloat(this.selectedResellerContract.contract_balance)
        balance =  balance - amount - charges
        console.log(amount, charges, balance)
        balance = this.convertCurrencyFormat(balance,true)
        
        return balance
      },
      inputNumberOnly(event, parentModel) {
        const numericValue = event.target.value.replace(/[^0-9.]/g, '');
        this.formData[parentModel] = numericValue;
      },
      convertCurrencyFormat(value, show00) {
        if (show00 == true) {
          return this.$refs.commonFunc.convertCurrencyFormat(value)
        } else {
          // Assuming this.transactionLimit is defined in your component's data
          return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00", "");
        }
      },
      showModal() {
        this.$refs['modal-withdrawal'].show()
        this.accessToken = this.data.assessToken;
        this.accessUsername = this.data.accessUsername;
        this.getReseller();
        this.getCurrency();
      },
      changeMerchant() {
        // this.selectedBanks=BankDefault;
        this.formData.resellerId = this.selectedReseller.account_db_code;
        this.formData.amountToReceiveCurrency = '';
        this.formData.withdrawAmount = '';
        //this.formData.withdrawAmountAfterCovertRate = '';
        this.formData.bankName = '';
        this.getMerchantContract();
      },
      changeContract() {
        var maxCharges = parseFloat(0)
        maxCharges = parseFloat(this.selectedResellerContract.contract_balance) - ( parseFloat(this.selectedResellerContract.contract_balance) * parseFloat((this.selectedResellerContract.payout_rate/100))) + parseFloat(this.selectedResellerContract.payout_extra_charges)
        console.log(parseFloat(this.selectedResellerContract.contract_balance))
        console.log(parseFloat(this.selectedResellerContract.payout_rate))
        console.log(parseFloat(this.selectedResellerContract.payout_extra_charges))
        this.currencyMax = maxCharges;
        
        this.formData.resellerId= this.selectedReseller.account_db_code
        this.formData.contractId = this.selectedResellerContract.contract_id;
        this.formData.amountToReceiveCurrency = '';
        this.formData.withdrawAmount = '';
       //this.formData.withdrawAmountAfterCovertRate = ''
        this.formData.bankName = '';
        this.formData.transactionDescription = '';
        this.formData.accountNumber = '';
        this.formData.accountName = '';

        if (this.minWithdrawal.length){
          this.currencyMin = this.minWithdrawal.find(element => element.currency==this.selectedResellerContract.contract_currency).amount || 0
        }
      },
      changeCurrency() {
        this.selectedBanks = BankDefault;
        //this.formData.password = ""
        //this.currencyMin = this.formData.amountToReceiveCurrency.minSettlementAmount
        this.formData.contractId = this.selectedResellerContract.contract_id;
        this.formData.extraField1 = {
          name: "",
          value: "",
          enabled: false
        },
        this.formData.extraField2 = {
          name: "",
          value: "",
          enabled: false
        },
        this.formData.extraField3 = {
          name: "",
          value: "",
          enabled: false
        }
        if (this.formData.amountToReceiveCurrency.shortName=='USDT'){
          this.formData.bankName = "USDT"
          this.formData.accountName = this.formData.amountToReceiveCurrency.displayName
        }
        if (this.formData.amountToReceiveCurrency.shortName == "INR") {
          this.formData.extraField1= {
            name: "IFSC Code",
            value: "",
            enabled: true,
          }
        }
        if (this.formData.amountToReceiveCurrency.shortName == "INR_NATIVE") {
          this.formData.extraField1= {
            name: "IFSC Code",
            value: "",
            enabled: true,
          }
        }
        this.getBankList();
        // if (this.formData.amountToReceiveCurrency.contract_currency && this.selectedResellerContract.contract_currency && this.formData.withdrawAmount){
        //   if (this.formData.amountToReceiveCurrency.contract_currency !== this.selectedResellerContract.contract_currency){
        //     this.convertCurrencyRates(this.formData.amountToReceiveCurrency.contract_currency,this.selectedResellerContract.contract_currency,this.formData.withdrawAmount)
        //   }else{
        //     //this.formData.withdrawAmountAfterCovertRate = this.formData.withdrawAmount
        //   }
         
        // }
        this.$v.formData.$touch();
       
      },
      convertCurrencyRates(theRateYouWant,current, amount){
      //   axios({
      //     method: 'GET',
      //     url: 'https://api.api-ninjas.com/v1/convertcurrency?want=EUR&have=USD&amount=5000',
      //     headers: { 'X-Api-Key': 'gWaz9Mqz6dqWBUVfrmzQhQ==xIuu43VOC0JU3hZE'},
      //     contentType: 'application/json',
      //     success: function(result) {
      //         console.log(result);
      //     },
      //     error: function ajaxError(jqXHR) {
      //         console.error('Error: ', jqXHR.responseText);
      //     }
      // });
      //   return
        console.log("convertCurrencyRates")
        this.$Progress.start();
        //this.formData.withdrawAmountAfterCovertRate = 0
        this.loading = true
        this.disabled = true
        axios({
          method: "get",
          url: `https://api.api-ninjas.com/v1/convertcurrency?want=${theRateYouWant}&have=${current}&amount=${amount}`,
          headers: { 'X-Api-Key': 'gWaz9Mqz6dqWBUVfrmzQhQ==xIuu43VOC0JU3hZE'},
          contentType: 'application/json'
        }).then((response) => {
          var resData = response.data;
          console.log(resData)
          //this.formData.withdrawAmountAfterCovertRate = resData.new_amount
          this.loading = false;
          this.disabled = false
          this.$Progress.finish();
        }).catch((error) => {
          this.loading = false;
          this.disabled = false
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `${error}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
        });
      },
      getBankList() {
        console.log("getBankList")
        this.$Progress.start();
        this.loading = true
        this.disabled = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        bodyFormData.append("currency", this.formData.amountToReceiveCurrency.shortName);
        var posturl = ""
        if (this.formData.amountToReceiveCurrency.shortName == 'MYR') {
          posturl = "/api/bank_list_my.json"
        } else if (this.formData.amountToReceiveCurrency.shortName == 'IDR') {
          posturl = "/api/bank_list_id.json"
        }else if (this.formData.amountToReceiveCurrency.shortName == "IDR_NATIVE") {
          posturl = "/api/bank_list_id.json";
        } else if (this.formData.amountToReceiveCurrency.shortName == 'VND') {
          posturl = "/api/bank_list_vn.json"
        } else if (this.formData.amountToReceiveCurrency.shortName == "INR"){
          posturl = "/api/bank_list_in.json";
        }else if (this.formData.amountToReceiveCurrency.shortName == "INR_NATIVE"){
          posturl = "/api/bank_list_in.json";
        }else if (this.formData.amountToReceiveCurrency.shortName == "BRL"){
          posturl = "/api/bank_list_brl.json";
        }else {
          this.banksList = []
          this.loading = false;
          this.disabled = false
          this.$Progress.finish();
          return
        }
        axios({
          method: "get",
          url: posturl,
          data: bodyFormData,
          headers: {
            "Content-Type": "multipart/form-data"
          },
        }).then((response) => {
          var resData = response.data;
          console.log(resData.result)
          if (resData.status == 200) {
            this.selectedBanks = BankDefault
            this.banksList = [this.selectedBanks]
            const popular = []
            const other = []
            resData.result.forEach(element => {
              if (element.category === 'popular') {
                popular.push(element);
              } else {
                other.push(element);
              }
            });
            this.banksList = [{
              category: 'Popular',
              list: popular
            }, {
              category: 'Other',
              list: other
            }]
            this.selectedBanks = this.banksList[0].list[0];
            this.formData.bankName = this.selectedBanks.name;
          } else if (resData.status == 440) {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              html: `${resData.message}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              html: `${resData.message}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
          }
          this.loading = false;
          this.disabled = false
          this.$Progress.finish();
        }).catch((error) => {
          this.loading = false;
          this.disabled = false
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `${error}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
        });
      },
      getReseller() {
        if(cancel !== undefined) cancel();
        console.log("getReseller")
        this.$Progress.start();
        this.loading = true
        this.disabled = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        bodyFormData.append("page", "1");
        bodyFormData.append("limit", "999999");
        bodyFormData.append("resellerId", (this.account_type==='admin') ? "" : "all", );
        bodyFormData.append("searchReseller", "")
        axios({
          method: "post",
          url: appConfig.APIHostAdmin + 'controller/admin/getAllResellerList',
          data: bodyFormData,
          headers: {
            "Content-Type": "multipart/form-data"
          },
          cancelToken: new CancelToken(c => cancel = c)
        }).then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
              this.reseller = []
              this.selectedReseller = ResellerDefault
              
              resData.data.forEach((element) => {
                this.reseller.push(element);
              });
              
              if (this.account_type=='admin'){
                if (this.reseller.length>1){
                 this.selectedReseller = this.reseller[1];
                }else{
                  this.selectedReseller = this.reseller[0];
                }
              }

              if (this.account_type=='reseller'){
                this.reseller=[]
                this.selectedReseller = resData.current_account
              }
            
              this.getResellerContract()

          } else if (resData.status == 440) {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              html: `${resData.message}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              html: `${resData.message}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
            this.loading = false;
            this.disabled = false
          }
          this.$Progress.finish();
        }).catch((error) => {
          this.loading = false;
          this.disabled = false
          this.$Progress.finish();
          console.log(error)
        });
      },
      getResellerContract() {
        console.log("getResellerContract");
        this.$Progress.start();
        this.loading = true;
        this.disabled = true;
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        bodyFormData.append("page", "1");
        bodyFormData.append("limit", "9999999");
        bodyFormData.append("reseller",  this.selectedReseller.account_db_code);
        axios({
          method: "post",
          url: appConfig.APIHostAdmin + 'controller/admin/getContractListForReseller',
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then((response) => {
            var resData = response.data;
            if (resData.status == 200) {
                this.selectedResellerContract = resellerContractDefault
                this.resellerContract = [];
                resData.data.forEach((element) => {
                  this.resellerContract.push(element);
                });
                if (this.resellerContract.length>0){
                  this.selectedResellerContract = this.resellerContract[0];
                  this.currencyMax = parseFloat(this.selectedResellerContract.contract_balance)
                }
                this.changeContract()
            } else if (resData.status == 440) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                html: `${resData.message}.`,
                confirmButtonColor: "#222",
                confirmButtonText: this.$t("siteLang.Done"),
              });
              localStorage.clear();
              this.$router.push({
                path: "/login",
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                html: `${resData.message}.`,
                confirmButtonColor: "#222",
                confirmButtonText: this.$t("siteLang.Done"),
              });
            }
            this.loading = false;
            this.disabled = false;
            this.$Progress.finish();
          })
          .catch((error) => {
            this.loading = false;
            this.disabled = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${error}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          });
      },
      getCurrency() {
        console.log("getCurrency")
        this.$Progress.start();
        this.loading = true
        this.disabled = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        const uniqueParam = `nocache=${new Date().getTime()}`;
        axios({
          method: "get",
          url: '/api/currency_list.json?' + uniqueParam,
          data: bodyFormData,
          headers: {
            "Content-Type": "multipart/form-data"
          },
        }).then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            this.currencyList = []
            this.currencyChargePercentage = 0
            this.currencyChargePercentage = resData.currencyChargePercentage
            resData.localBankTransfer.forEach((element) => {
              this.currencyList.push(element);
            });
            // resData.internationalBankTransfer.forEach((element) => {
            //   this.currencyList.push(element);
            // });
            resData.cryptoTransfer.forEach((element) => {
              //temp close btc first
              if (element.shortName!=='BTC'){
                this.currencyList.push(element);
              }
            });
            this.minWithdrawal = resData.minWithdrawal;
            //this.currencyMin = parseFloat(this.formData.amountToReceiveCurrency.minSettlementAmount) || 0.00
          } else if (resData.status == 440) {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              html: `${resData.message}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              html: `${resData.message}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
          }
          this.loading = false;
          this.disabled = false
          this.$Progress.finish();
        }).catch((error) => {
          this.loading = false;
          this.disabled = false
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `${error}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
        });
      },
      resetModal() {
        this.selectedReseller = ResellerDefault
        this.selectedResellerContract = resellerContractDefault
        this.selectedBanks = BankDefault
        this.modalSubmit = false;
        this.modalLoading = false;
        this.formData= {
          resellerId: "",
          contractId: "",
          amountToReceiveCurrency: "",
          bankName: "",
          accountNumber: "",
          accountName: "",
          transactionDescription: "",
          withdrawAmount: "",
          extraField1: {
            name: "",
            value: "",
            enabled: false,
          },
          extraField2: {
            name: "",
            value: "",
            enabled: false,
          },
          extraField3: {
            name: "",
            value: "",
            enabled: false,
          }
        }
      },
      switchVisibility() {
        (this.classicon = this.classicon === "uil uil-eye-slash" ? "uil uil-eye" : "uil uil-eye-slash"),
        (this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password");
      },
      addWithdrawalRequest() {
        this.modalSubmit = true
        this.$v.formData.$touch();
        if (this.$v.formData.$invalid) {
          return;
        } else {
          this.$Progress.start();
          this.modalLoading = true;
          var bodyFormData = new FormData();
          bodyFormData.append('accessUsername', this.accessUsername);
          bodyFormData.append('accessToken', this.accessToken);
          bodyFormData.append('resellerId', this.formData.resellerId);
          bodyFormData.append('contractId', this.formData.contractId);
          bodyFormData.append('withdrawAmount', this.formData.withdrawAmount);
          bodyFormData.append('bankName', this.formData.bankName);
          bodyFormData.append('accountNumber', this.formData.accountNumber);
          bodyFormData.append('accountName', this.formData.accountName);
          bodyFormData.append('transactionDescription', this.formData.transactionDescription);
          bodyFormData.append('extraField1', JSON.stringify(this.formData.extraField1));
          bodyFormData.append('extraField2', JSON.stringify(this.formData.extraField2));
          bodyFormData.append('extraField3', JSON.stringify(this.formData.extraField3));
          bodyFormData.append('amountToReceiveCurrency',JSON.stringify(this.formData.amountToReceiveCurrency));
          axios({
            method: "post",
            url: appConfig.APIHostAdmin + 'controller/admin/makeNewResellerWithdraw',
            data: bodyFormData,
            headers: {
              "Content-Type": "multipart/form-data"
            },
          }).then((response) => {
            this.returnData = []
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              Swal.fire({
                icon: 'success',
                title: 'Withdrawal Request Created',
                html: `Your withdrawal request has been successfully created.!`,
                confirmButtonColor: '#FA6541',
                confirmButtonText: this.$t('siteLang.Done')
              }).then(() => {
                this.$refs['modal-withdrawal'].hide()
                this.$emit('callParentFunction');
                if (this.$route.name!=="pending withdrawal"){
                  this.$router.push({
                    name: "pending withdrawal",
                  });
                }
              })
            } else if (resData.status == 440) {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
              localStorage.clear();
              this.$router.push({
                path: "/login",
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
            }
            this.modalLoading = false;
            this.modalSubmit = false
            this.$Progress.finish();
          }).catch((error) => {
            this.modalLoading = false;
            this.modalSubmit = false
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
          });
        }
      },
      async reload() {
        try {
          await this.$nextTick(); // Wait for the component to be fully mounted
          const commonFunc = this.$refs.commonFunc;
          if (commonFunc && commonFunc.getFullData) {
            const data = await commonFunc.getFullData();
            this.account_type = data.account_type;
          } else {
            // Handle the case when commonFunc or getFullData is undefined
            // Perform appropriate actions or show a fallback message to the user
          }
        } catch (error) {
          // Handle the error silently without propagating it further
        }
      },
    }
  };
</script>
<style scoped>
  #eye_current,
  #eye,
  #eye2,
  #eye3 {
    position: absolute;
    top: 4px;
    padding: 5px 10px;
    right: 5px;
    text-align: center;
    width: 32px;
    color: #999;
    z-index: 3;
    background-color: #fff;
    cursor: pointer;
  }
</style>